import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql} from "gatsby"
import Container from '../../styles/container'
import BlockContent from '@sanity/block-content-to-react'

const ContainerWrapper = styled(Container)`
  margin-bottom: ${props => props.theme.space[12]};

  h2 {
    margin-bottom: 0;
    text-align: center;

    span {
      color: #D6B47A;
    }
  }

  h3 {
    text-align: center;
    color: ${props => props.theme.colors.grey};
    margin-top: 0;
  }

  p {
    width: 100%;
    margin: 0 auto; 
    text-align: center;

    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      width: 60%;
    }
  }
`;

export default function Gallery({path}) {
  return (
    <StaticQuery
      query={graphql`
        query Titletext {
          sanityLandingpage {
            _rawTitletext(resolveReferences: {maxDepth: 10})
          }
        }`}
      render={data => (
        <ContainerWrapper>
          <h2>Herzlich Willkommen</h2>
          {/* <h3>Massgeschneiderte Muster fuer Sie</h3> */}
          <BlockContent blocks={data.sanityLandingpage._rawTitletext} />
        </ContainerWrapper>
    )}
    />
  )
}
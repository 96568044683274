import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import { Parallax  } from 'react-scroll-parallax';


const GalleryContainer = styled.div`
  width: 140vw;
  overflow-x: hidden !important;
  display: grid;
  grid-template-columns:repeat(20, 1fr);
  grid-template-rows: repeat(8, 15px);
  column-gap: 7px;
  margin-bottom: 10vh;
  margin-left: -10%;
  
  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-top: ${props => props.theme.space[8]};
    grid-template-rows: repeat(8, 40px);
    column-gap: 18px;
    margin-left: 0;
    margin-bottom: 15vh;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-top: ${props => props.theme.space[8]};
    grid-template-rows: repeat(8, 70px);
    column-gap: 35px;
    margin-left: 0;
  }
`; 


const FirstImage = styled.div`
  background: url(${props => props.background});
  background-size: cover;
  grid-column: 1 / span 3;
  grid-row: 2 / span 4;
  /* border: 2px solid ${props => props.theme.colors.primary}; */
`;

const SecondImage = styled.div`
  background: url(${props => props.background});
  background-size: cover;
  grid-column: 4 / span 4;
  grid-row: 1 / span 8;
  /* border: 2px solid ${props => props.theme.colors.primary}; */
`;

const ThirdImage = styled.div`
  background: url(${props => props.background});
  background-size: cover;
  grid-column: 8 / span 4;
  grid-row: 3 / span 5;
  /* border: 2px solid ${props => props.theme.colors.primary}; */
`;

const FourthImage = styled.div`
  background: url(${props => props.background});
  background-size: cover;
  grid-column: 12 / span 4;
  grid-row: 4 / span 5;
  /* border: 2px solid ${props => props.theme.colors.primary}; */
`;

const FifthImage = styled.div`
  background-color: green;
  grid-column: 16 / span 4;
  grid-row: 5 / span 4;
`

export default function Gallery({path}) {
  return (
    <StaticQuery
      query={graphql`

        fragment GalleryImage on SanityMusterImage {
          asset {
            _id
            path
            fixed {
              src
            }
            fluid(maxHeight: 420, maxWidth: 420) {
              src
            }
          }
        }

        query GalleryImages {
          sanityLandingpage {
            firstImage {
              ...GalleryImage
            }
            secondImage {
              ...GalleryImage
            }
            thirdImage {
              ...GalleryImage
            }
            fourthImage {
              ...GalleryImage
            }
          }
        }`}
      render={data => (
        <Parallax className="custom-class" x={[0, -10]} y={[0,0]} tagOuter="figure">
          <GalleryContainer>
            <FirstImage background={data.sanityLandingpage.firstImage.asset.fluid.src}/>
            <SecondImage background={data.sanityLandingpage.secondImage.asset.fluid.src} />
            <ThirdImage  background={data.sanityLandingpage.thirdImage.asset.fluid.src}/>
            <FourthImage background={data.sanityLandingpage.fourthImage.asset.fluid.src} />
            {/* <FifthImage /> */}
          </GalleryContainer>
        </Parallax>
    )}
    />
  )
}
import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql} from "gatsby"
import Container from '../../styles/container'
import BlockContent from '@sanity/block-content-to-react'
import { Parallax  } from 'react-scroll-parallax';
import { Link } from "gatsby"

const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.space[12]};
  margin-bottom: ${props => props.theme.space[12]};

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: row;
    margin-top: ${props => props.theme.space[13]};
    margin-bottom: ${props => props.theme.space[13]};
  }
`

const Images = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 0.5fr 0.2fr;
  grid-template-rows: 50px 200px 30px;
  
  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-rows: 50px 350px 30px;
    height: 300px;
    min-height: 400px;
  }

  .background-image {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    height: 100%;
    width: 100%;
  }

  .foreground-image {
    grid-column: 2 / span 2;
    grid-row: 2 / span 2;
    height: 100%;
    width: 100%;
  }

  .parallax-inner {
    height: 100%;
    width: 100%;
    position: relative;
  }
`;

const ForegroundImage = styled.div`
    border: ${props => props.theme.space[2]} solid white;
    background: url(${props => props.background});
    background-size: cover;
`;

const BackgroundImage = styled.div`
    background: url(${props => props.background});
    background-size: cover;
    height: 100%;
    width: 100%;
    position: relative;
`;

const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    width: 60%;
    height: 300px;
    min-height: 400px;
    padding: ${props => props.theme.space[5]};
  }

  a {
    text-decoration: none;
    font-weight: bold;
  }
`;


export default function MyWork({path}) {
  return (
    <StaticQuery
      query={graphql`
        fragment WorkImages on SanityMusterImage {
          asset {
            _id
            path
            fluid(maxHeight: 520, maxWidth: 520) {
              src
            }
          }
        }

        query Asdf {
          sanityLandingpage {
            _rawMywork(resolveReferences: {maxDepth: 10})
            workimagebackground {
              ...GalleryImage
            }
            workimageforeground {
              ...GalleryImage
            }
          }
        }`}
      render={data => (
        <ContainerWrapper>
          <Images>
            <Parallax className="background-image" x={[0, 0]} y={[0,0]}>
              <BackgroundImage className="background-image" background={data.sanityLandingpage.workimagebackground.asset.fluid.src} />
            </Parallax>
            <Parallax className="foreground-image" x={[-8, 0]} y={[-5,0]}>
              <ForegroundImage className="foreground-image" background={data.sanityLandingpage.workimageforeground.asset.fluid.src} />
            </Parallax>
          </Images>
          <Text>
            <h2>Meine Muster</h2>
            <BlockContent blocks={data.sanityLandingpage._rawMywork} />
            <Link to="portfolio">Zum Portfolio &rarr;</Link>
          </Text>
        </ContainerWrapper>
    )}
    />
  )
}
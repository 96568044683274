import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql} from "gatsby"
import CaroVideo from '../../assets/videos/caro_vid.mp4'

const VideoWrapper = styled.div`
  width: 100vw;
  /* min-height: 30vh; */

  video {
    position: relative;
    height: 100%;
    width: 100%;
  }

  p {
    width: 100%;
    margin: 0 auto; 
    text-align: center;

    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      width: 60%;
    }
  }
`;

export default function Video({path}) {
  return (
    <StaticQuery
      query={graphql`
        query Video {
          sanityLandingpage {
            _rawTitletext(resolveReferences: {maxDepth: 10})
          }
        }`}
      render={data => (
        <VideoWrapper>
          <video id="video1" autoPlay muted controls="" loop>
            <source src={CaroVideo} type="video/mp4"  />
          </video>
        </VideoWrapper>
      )}
    />
  )
}

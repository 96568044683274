import React from 'react';
import styled from 'styled-components'
import { StaticQuery, graphql} from "gatsby"
import Container from '../../styles/container'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from "gatsby"

const Wrapper = styled.div`
  position: relative;
  background: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 60vh;
  margin-bottom: ${props => props.theme.space[12]};

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-bottom: ${props => props.theme.space[13]};
    min-height: 50vh;
  }
`

const FilterContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right bottom, hsla(0, 0%, 100%, 1), hsla(0, 0%, 80%, .95), 
                                    hsla(4, 31%, 95%, .9), hsla(11, 53%, 56%, .6));
  z-index: 70;
`

const ContainerWrapper = styled.div`
  z-index: 200;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.space[10]} 0;
`

const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 129;
  color: hsla(0, 0%, 0%, 1);

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    width: 70%;
    height: 300px;
    min-height: 400px;
    padding: ${props => props.theme.space[5]};
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: hsla(0, 0%, 0%, 1);
  }
`

export default function Contact ({path}) {
  return (
    <StaticQuery
      query={graphql`
        fragment ContactImage on SanityMusterImage {
          asset {
            _id
            path
            fluid(maxHeight: 620, maxWidth: 1420) {
              src
            }
          }
        }

        query Contact {
          sanityLandingpage {
            _rawContacttext(resolveReferences: {maxDepth: 10})
            contactImage {
              ...ContactImage
            }
          }
        }`}
      render={data => (
        <Wrapper background={data.sanityLandingpage.contactImage.asset.fluid.src}>
          <FilterContainer />
          <ContainerWrapper>
            <Container>
              <Text>
                <BlockContent blocks={data.sanityLandingpage._rawContacttext} />
                <Link to='contact'>Kontakt &rarr;</Link>
              </Text>
            </Container>
          </ContainerWrapper>
        </Wrapper>
      )}
    />
  )
}